import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {
  private orderUrl = environment.orderApi;

  constructor(private router: Router, private http: HttpClient) { }

  getOrderTemplates({page, limit, filters}): Observable<any> {
    const query = `?page=${page}&limit=${limit}`

    let params = new HttpParams();

    if (Object.keys(filters).length) {
      Object.keys(filters).forEach(k => {
        if (filters[k] && typeof filters[k] != 'object' && k !== 'device') {
          params = params.set(k, filters[k]);
        }
      });
    }
    return this.http
      .get(`${this.orderUrl}template${query}`,{ params })
      .pipe(catchError((error: any) => throwError(error)));
  }

  saveTemplate(payload: {name, branch_id}): Observable<any> {

    return this.http
      .post(`${this.orderUrl}template`, payload )
      .pipe(catchError((error: any) => throwError(error)));
  }

  deleteTemplate(templateId: string): Observable<any> {
    
    return this.http
      .delete(`${this.orderUrl}template/${templateId}`, {})
      .pipe(catchError((error: any) => throwError(error)));
  }

  getTemplateById(templateId: string): Observable<any> {
    return this.http
    .get(`${this.orderUrl}template/${templateId}`, {})
    .pipe(catchError((error: any) => throwError(error)));
  }

  useTemplate(templateId: string): Observable<any> {
    return this.http
    .post(`${this.orderUrl}template/${templateId}/use`, {})
    .pipe(catchError((error: any) => throwError(error)));
  }
}
